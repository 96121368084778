<template>
    <section ref="members" id="meet-the-members">
        <div class="container">
          <div ref="member" class="member">
            <div class="member-info">
              <img src="@/assets/Cameron.png" alt="Chern_eazy">
              <h3 class="member-name">Chern_eazy</h3>
            <h3 class="member-job-title">Celestial Systems Architect & Navigator</h3>
            </div>
            <div class="member-description">
              <p>
                    Born into a family of inventors and pioneers, Chern_eazy was destined for greatness. Even as a child,
                    he exhibited an uncanny ability to dissect and rebuild complex machines. His fascination with technology
                    led him to a prestigious university where he earned a degree in computer science. Throughout his academic
                    journey, his girlfriend Sharonne stood by his side, offering encouragement and love. Chern_eazy's talent
                    for innovation caught the attention of the Project Stellar's founders, who knew he was the ideal
                    candidate to manage the facility's advanced technological systems and develop the software needed for the
                    Celestial Voyager System. Now, as the team's technology specialist, Chern_eazy relentlessly pushes the
                    boundaries of what's possible, driven by his dream of reaching the stars.
                </p>
            </div>
          </div>
          </div>

          <div class="container">
            <div ref="member" class="member">
              <div class="member-info">
                <img src="@/assets/Brandon.png" alt="Brandon">
                <h3 class="member-name">BrandonOrlando2</h3>
              <h3 class="member-job-title">Marine Defense Strategist & Researcher</h3>
              </div>
              <div class="member-description">
                <p>
                    Growing up in a small coastal town, BrandonOrlando2's love for the ocean was ingrained in him from a young
                    age. As a teenager, he joined the military, quickly rising through the ranks and becoming an expert in maritime
                    security. After leaving the service, he met his wife Kristen, a marine biologist who opened his eyes to the
                    wonders of scientific research. Combining his military expertise with his newfound love for science,
                    BrandonOrlando2 began his career as a security and research specialist. When he learned about Project Stellar,
                    he knew it was the perfect opportunity to protect his beloved ocean and contribute to humanity's quest to explore
                    the cosmos. As the team's security and research specialist, he diligently safeguards the facility and its inhabitants
                    while offering vital research insights for the Celestial Voyager System.
                </p>
              </div>
            </div>
          </div>

          <div class="container">
            <div ref="member" class="member">
              <div class="member-info">
            <img src="@/assets/Alex.png" alt="Cyburthug">
             <h3 class="member-name">Cyburthug96</h3>
              <h3 class="member-job-title">Subaquatic Infrastructure Engineer & Star Energy Expert</h3>
              </div>
              <div class="member-description">
                <p>
                    Cyburthug96's passion for engineering was ignited when, as a young boy, he watched his father repair the family's
                    electrical appliances. He quickly became an engineering prodigy, earning a scholarship to study at a prestigious technical
                    institute. It was there that he met Adriane, a fellow engineering student who would become his wife and greatest supporter.
                    As the world's leading electrical and engineer specialist, Cyburthug96 was handpicked to join Project Stellar.
                    Tasked with designing and maintaining the critical systems needed to power the Celestial Voyager System, he thrives on the
                    challenges of deep-sea engineering. Fueled by his love for Adriane and their shared dreams, Cyburthug96 is determined to
                    help humanity conquer the stars.
                </p>
              </div>
            </div>
            </div>
    </section>
  </template>
  
  <script>
/* global gsap */
export default {
  name: "meet-the-members",
  mounted() {
  // Get the members section and all member divs
  var membersSection = document.querySelector("#meet-the-members");
  var memberDivs = document.querySelectorAll("#meet-the-members .member");

  // Set initial position and opacity for each member div
  gsap.set(memberDivs, { x: -100, opacity: 0 });

  // Function to animate the member divs
  function animateMemberDivs() {
    gsap.to(memberDivs, {
      x: 0,
      opacity: 1,
      duration: 1,
      stagger: 0.5,
      ease: "power2.out",
    });
  }

  // Create an Intersection Observer
  var observer = new IntersectionObserver(
    function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          // If the members section is visible in the viewport, animate the divs
          animateMemberDivs();

          // Disconnect the observer after animating to prevent re-triggering the animation
          observer.disconnect();
        }
      });
    },
    {
      // Set the threshold to 0.1, meaning the observer will trigger when 10% of the members section is visible
      threshold: 0.5,
    }
  );

  // Observe the members section
  observer.observe(membersSection);
}
}
  </script>
  
  <style scoped>

#meet-the-members {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-image: url('@/assets/The-Team.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
  }

  @media (min-width: 1920px) and (max-width: 2100px) {

    #meet-the-members {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-image: url('@/assets/The-Team.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .container {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 10px;
        padding-bottom: 10px;
      }

    .member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.45rem;
        background-color: white;
        border-radius: 20px;
      }

    .member-info {
      align-items: center;
      margin: 20px;
    }
  
    .member img {
      width: 150px;
      height: 150px;
      border-radius: 20%;
      margin-bottom: 1rem;
      max-width: 300px;
    }
    
    .member-name {
      font-size: 1rem;
      font-family: 'Ubuntu', sans-serif;
      text-transform: uppercase;
      color: #181A18;
      margin-bottom: 10px;
    }
    
    .member-job-title {
      font-size: 0.8rem;
      font-family: 'Open Sans', sans-serif;
      margin-bottom: 0.5rem;
      color: var(--dark-blue);
    }
    
    .member-description {
      font-size: 0.8rem;
      font-family: 'Open Sans', sans-serif;
      line-height: 1.5;
      color: #181A18;
      text-align: left;
      margin-left: 10px;
      margin-right: 10px;
      background-color: var(--light-blue);
      border: var(--blue);
      border-width: 10px;
      border-radius: 10px;
      padding: 10px;
    }

}

@media (min-width: 2200px) {

  #meet-the-members {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-image: url('@/assets/The-Team.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

    .container {
        align-content: center;
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 10px;
        padding-bottom: 10px;
      }

    .member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        background-color: white;
        border-radius: 20px;
      }

      .member-info {
        align-items: center;
        margin: 20px;
      }
    
      .member img {
        width: 200px;
        height: 200px;
        border-radius: 20%;
        margin-bottom: 1rem;
        max-width: 800px;
      }
      
      .member-name {
        font-size: 1.2rem;
        font-family: 'Ubuntu', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        color: #181A18;
        margin-bottom: 10px;
      }
      
      .member-job-title {
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif;
        margin-bottom: 0.5rem;
        color: var(--dark-blue);
      }
      
      .member-description {
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif;
        line-height: 1.5;
        color: #181A18;
        text-align: left;
        margin-left: 30px;
        margin-right: 30px;
        background-color: var(--light-blue);
        border: var(--blue);
        border-width: 10px;
        border-radius: 10px;
        padding: 30px;
      }
  }
  </style>
  