<template>
  <section id="hero">
    <div id="heroMenu">
        <div class="top-right-buttons">
            <button class="nav-button">The Team</button>
            <button class="nav-button">Mods</button>
            <button class="nav-button">The Server</button>
        </div>
        <div class="hero-content">
        <div>
        <h1 class="header">Project Stellar</h1>
        <p class="subtitle">Unlock a world of mysteries beneath the surface</p>
        </div>
        <button class="join-now-button">Join Now</button>
        </div>
        </div>
  </section>
</template>

<script>
/* global gsap */
export default {
  name: "hero-section",
  mounted() {
 // Get all buttons with the class "pop-button"
 var popButtons = document.querySelectorAll(".join-now-button");
  
  // Add event listeners for each button
  popButtons.forEach(function (button) {
    // Add a "mouseover" event listener to scale the button up
    button.addEventListener("mouseover", function () {
      gsap.to(button, { scale: 1.1, duration: 0.2 });
    });

    // Add a "mouseout" event listener to scale the button back to its original size
    button.addEventListener("mouseout", function () {
      gsap.to(button, { scale: 1, duration: 0.2 });
    });

    // Add a "mousedown" event listener to shake the button while it's clicked
    button.addEventListener("mouseover", function () {
      gsap.to(button, {
        rotation: 5,
        xPercent: -4,
        duration: 0.1,
        yoyo: true,
        repeat: 1,
        ease: "none",
      });
    });

    // Add a "mouseup" event listener to stop the shaking when the button is released
    button.addEventListener("mouseup", function () {
      gsap.killTweensOf(button);
      gsap.to(button, { rotation: 0, xPercent: 0, duration: 0.1 });
    })
  })
}
};

</script>

<style scoped>
 /* Top-right buttons */
 .top-right-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    margin-right: 2rem;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-button {
    background-color: var(--complementary-1);
    color: var(--light-blue);
    border: none;
    border-radius: 5px;
    padding: 15px 70px;
    margin-left: 40px;
    font-size: 1rem;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .nav-button:hover {
    background-color: var(--complementary-2);
  }
  
  /* Centered header and subtitle */
  .header {
    font-size: 10rem;
    font-family: 'Ubuntu', sans-serif;
    margin-bottom: 1rem;
    color: #181A18;
    text-shadow: 3px 4px 1px rgba(255, 255, 255, 0.8);
  }
  
  .subtitle {
    font-size: 2rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 30px;
    margin-bottom: 2rem;
    color: rgb(255, 255, 255);
  }

  .join-now-button {
    /* Customize the button appearance as needed */
    background-color: var(--complementary-1);
    color: #ffffff;
    border:#0077b6;
    border-radius: 5px;
    margin-top: 20px;
    padding: 15px 70px;
    font-size: 1.2rem;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .join-now-button:hover {
    cursor: pointer;
    background-color: var(--dark-blue);
    transition: background-color 0.3s ease;
  }
  
  #hero {
    text-align: center;
    position: relative;
    background-image: url('@/assets/Hero.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display:flex;
    justify-content: center;
    align-items: center;
  }

.hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 300px;
}
  
  .headline {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .subheading {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .foreground {
    position: absolute;
    bottom: 0;
    /* Add styles for the seagulls, rocks, or waves elements here */
  }
</style>
