<template>
    <section id="underwater-transition">
        <div class="container">
        <div class="story.section">
            <div class="story-header">
                The Story
            </div>
            <div class="story-content">
            <p>
                In a world teetering on the brink of disaster, three intrepid heroes dared
                to dream of a better future. Chern_eazy, BrandonOrlando2, and CyburThug96 were
                once ordinary people, each with their unique skills and expertise. They saw the
                damage that humanity had inflicted upon the Earth, and they knew that the only
                hope for survival lay beyond our planet. United by their passion for exploration
                and the pursuit of knowledge, they embarked on a mission that would forever change
                the course of history.
            </p>
            <p>
                As the Earth's resources dwindled and ecosystems collapsed, it became clear that the
                only way to save humanity was to venture deep beneath the ocean's surface. Hidden from
                the world above, our heroes built an underwater base, harnessing the power of the ocean
                to fuel their ambitious plan. In the dark depths, they worked tirelessly, driven by
                their desperation to find a solution to the world's problems. Their research would
                unlock the secrets of an ancient technology, the Celestial Modulus, capable of propelling
                humanity to the stars.
            </p>
            <p>
                The journey was not without its challenges. Each hero faced their own trials and tribulations,
                battling not only the harsh conditions of their underwater abode but also their own fears
                and doubts. Yet, their unbreakable bond and unwavering dedication to their cause carried them
                through even the darkest moments. As they gazed into the infinite expanse of the cosmos, they
                knew that their sacrifices and struggles were not in vain. Through their heroism, Chern_eazy,
                BrandonOrlando2, and CyburThug96 became the harbingers of hope for a world on the edge of ruin,
                paving the way for humanity's ascension to the stars and a new era of peace and prosperity.
            </p>
            </div>
        </div>
        </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "underwater-transition"
  }
  </script>
  
  <style scoped>
    #underwater-transition {
    padding: 100px 0;
    text-align: center;
    background-image: url("@/assets/Server.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media (min-width: 1920px) and (max-width: 2100px) {
#underwater-transition {
  padding: 100px 0;
  text-align: center;
  background-image: url("@/assets/Server.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  }
}

  @media (min-width: 2200px) {

#underwater-transition {
  padding: 100px 0;
  text-align: center;
  background-image: url("@/assets/Server.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  }

.story-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
  }
  
  .story-header {
    font-size: 1rem;
    font-family: 'Ubuntu', sans-serif;
    text-transform: uppercase;
    color: #181A18;
    margin-bottom: 10px;
  }
  
  .story-content {
    font-size: 0.8rem;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    color: #181A18;
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--light-blue);
    border: var(--blue);
    border-width: 10px;
    border-radius: 10px;
    padding: 10px;
  }
}
.story-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 100vh;
      }
      
      .story-header {
        font-size: 1rem;
        font-family: 'Ubuntu', sans-serif;
        text-transform: uppercase;
        color: #181A18;
        margin-bottom: 10px;
      }
      
      .story-content {
        font-size: 0.8rem;
        font-family: 'Open Sans', sans-serif;
        line-height: 1.5;
        color: #181A18;
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        background-color: var(--light-blue);
        border: var(--blue);
        border-width: 10px;
        border-radius: 10px;
        padding: 10px;
      }

  </style>