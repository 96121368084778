<template>
  <div id="app">
    <Hero />
    <UnderwaterTransition />
    <MeetTheMembers />
    <CallToAction />
  </div>
</template>

<script>
import Hero from './components/Hero.vue';
import UnderwaterTransition from './components/UnderwaterTransition.vue';
import MeetTheMembers from './components/MeetTheMembers.vue';
import CallToAction from './components/CallToAction.vue';

export default {
  components: {
    Hero,
    UnderwaterTransition,
    MeetTheMembers,
    CallToAction
  }
}
</script>

<head>
  <link rel="icon" href="@public/favicon.ico" type="image/x-icon">
</head>

<style>
  /* CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}

section {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    position: relative;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Color Scheme */
:root {
  --blue: #0077b6;
  --dark-blue: #023e8a;
  --light-blue: #caf0f8;
  --complementary-1: #00b4d8;
  --complementary-2: #90e0ef;
}

/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: var(--blue);
  color: var(--light-blue);
}
</style>

