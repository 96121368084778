<template>
    <section id="hero">
      <h3>Join Today!</h3>
        <button class="cta-button">Join Now</button>
    </section>
  </template>
  
  <script>
  export default {
    name: "call-to-action"
  }
  </script>
  
  <style scoped>
    #call-to-action {
    padding: 100px 0;
    text-align: center;
    /* background-image: url('path/to/call-to-action-image.jpg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
.cta-button {
    background-color: var(--complementary-1);
    color: var(--light-blue);
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.cta-button:hover {
    background-color: var(--complementary-2);
  }
  </style>
  